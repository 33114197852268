import React, { useState } from "react";
import Joi from "joi-browser";
import axios from "axios";

export const SignUpForm = () => {
  const [signUpFormDataState, setSignUpFormDataState] = useState({
    signUpFullName: "",
    signUpOrg: "",
    signUpPhone: "",
    signUpEmail: "",
    // 'signUpPassword': ''
  });

  const { signUpFullName, signUpOrg, signUpPhone, signUpEmail } =
    signUpFormDataState;
  const [signUpMessages, setSignUpMessages] = useState([]);
  const handleSignUpFormInputChange = ({ target }) => {
    const valueToAdd = target.value !== null ? target.value : "";

    setSignUpFormDataState({
      ...signUpFormDataState,
      [target.name]: valueToAdd,
    });
  };

  const signUpSchema = Joi.object().keys({
    FullName: Joi.string().min(3).required(),
    Organization: Joi.string().required(),
    Phone: Joi.string(),
    Email: Joi.string().email().required(),
    // Password: Joi.string().min(8).required()
  });

  const signUpUser = async (e) => {
    e.preventDefault();
    const FullName = signUpFullName;
    const Organization = signUpOrg;
    const Phone = signUpPhone;
    const Email = signUpEmail;
    // const Password = signUpPassword

    const validationResult = signUpSchema.validate({
      FullName,
      Organization,
      Phone,
      Email,
      // Password
    });

    if (validationResult.error) {
      const errorDetails = validationResult.error.details.map((error) => ({
        id: error.path[0],
        result: error.message,
      }));
      setSignUpMessages(errorDetails);
      return;
    } else {
      setSignUpMessages([]);
    }

    //Hacer peticion de sign up
    try {
      const data = {
        first_name: FullName,
        middle_name: "",
        last_name: Organization,
        email: Email,
        accepted_privacy_policy: true,
        phone: Phone,
      };

      await axios.post(
        "https://centralizedlogin.rplusrprogram.com/users",
        data
      );

      setSignUpMessages([
        {
          id: "login-user-1",
          result: "Please check your e-mail",
          color: "#ff0000",
        },
      ]);

      await axios.post("https://t9hvm5lyo7.execute-api.us-west-2.amazonaws.com/production/signupses", data);

      setSignUpFormDataState({
        signUpFullName: "",
        signUpOrg: "",
        signUpPhone: "",
        signUpEmail: "",
      });
    } catch (err) {
      const responseData = err.response.data;
      const error = { id: "error-login", result: responseData.result };
      setSignUpMessages([error]);
    }
  };

  return (
    <form
      className="flex flex-col Form-Fields items-center justify-evenly h-3/4"
      id="signupform"
      onSubmit={signUpUser}
    >
      <div className="flex flex-col w-9/12 Display-Form-Fields">
        <p className="text-xs font-medium mb-1">Full name</p>
        <input
          type="text"
          className={"px-2 h-11 " + (signUpFullName !== "" && "rounded-full")}
          name="signUpFullName"
          autoComplete="off"
          value={signUpFullName}
          onChange={handleSignUpFormInputChange}
        />
      </div>

      <div className="flex flex-col w-9/12 Display-Form-Fields">
        <p className="text-xs font-medium mb-1">Organization</p>
        <input
          type="text"
          className={"px-2 h-11 " + (signUpOrg !== "" && "rounded-full")}
          name="signUpOrg"
          autoComplete="off"
          value={signUpOrg}
          onChange={handleSignUpFormInputChange}
        />
      </div>

      <div className="flex flex-col w-9/12 Display-Form-Fields">
        <p className="text-xs font-medium mb-1">Phone</p>
        <input
          type="text"
          className={"px-2 h-11 " + (signUpPhone !== "" && "rounded-full")}
          name="signUpPhone"
          autoComplete="off"
          value={signUpPhone}
          onChange={handleSignUpFormInputChange}
        />
      </div>

      <div className="flex flex-col w-9/12">
        <p className="text-xs font-medium mb-1">Email</p>
        <input
          type="text"
          className={"px-2 h-11 " + (signUpEmail !== "" && "rounded-full")}
          name="signUpEmail"
          autoComplete="off"
          value={signUpEmail}
          onChange={handleSignUpFormInputChange}
        />
      </div>

      {signUpMessages && signUpMessages.length > 0 && (
        <div className="mt-3 mb-3 w-9/12">
          {signUpMessages.map((message) => (
            <div
              key={"sign-up-error-" + message.id}
              style={{
                color: message.color ? message.color : "#D82525",
              }}
            >
              {message.result}
            </div>
          ))}
        </div>
      )}

      <div className="w-9/12 mt-1">
        <button
          className="w-full h-11"
          type="submit"
          style={{
            color: "white",
            backgroundColor:
              signUpFullName !== null &&
              signUpFullName !== "" &&
              signUpOrg !== null &&
              signUpOrg !== "" &&
              signUpPhone !== null &&
              signUpPhone !== "" &&
              signUpEmail !== null &&
              signUpEmail !== ""
                ? "#707070"
                : "#B6BEC3",
          }}
        >
          Sign Up
        </button>
      </div>
    </form>
  );
};
