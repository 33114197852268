import React, { useState } from "react";
import Joi from "joi-browser";
import axios from "axios";
import { navigate } from "gatsby";
import {setEmail, setToken} from "../../reducers/authSlice";
import { useDispatch } from "react-redux";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip as ReactTooltip } from "react-tooltip";


export const SingInForm = () => {
  const dispatch = useDispatch();


  const strongPasswordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  const stringPasswordError = new Error("Password must be strong. At least one upper case alphabet. At least one lower case alphabet. At least one digit. At least one special character. Minimum eight in length");

  const signInChangePasswordSchema = Joi.object().keys({
    Email: Joi.string().email().required(),
    Password: Joi.string().min(8).required(),
    NewPassword: Joi.string().regex(strongPasswordRegex).required().error(stringPasswordError)
  });

  const signInSchema = Joi.object().keys({
    Email: Joi.string().email().required(),
    Password: Joi.string().min(8).required(),
  });

  const [signInMessages, setSignInMessages] = useState([]);

  const [signInFormDataState, setSignInFormDataState] = useState({
    signInEmail: "",
    signInPassword: "",
    signInNewPassword: "",
    showNewPassword: false,
  });

  const { signInEmail, signInPassword, signInNewPassword, showNewPassword } =
    signInFormDataState;

  const handleSignInFormInputChange = ({ target }) => {
    const valueToAdd = target.value != null ? target.value : "";

    setSignInFormDataState({
      ...signInFormDataState,
      [target.name]: valueToAdd,
    });
  };

  const signUserIn = async (e) => {
    e.preventDefault();

    const Email = signInEmail;
    const Password = signInPassword;
    const NewPassword = signInNewPassword;

    let validationResult = null;

    if (showNewPassword) {
      validationResult = signInChangePasswordSchema.validate({
        Email,
        Password,
        NewPassword,
      });
    } else {
      validationResult = signInSchema.validate({ Email, Password });
    }

    if (validationResult.error ) {
      let errorDetails = {};
      if(validationResult.error.details) {
        errorDetails = validationResult.error.details.map((error) => ({
          id: error.path[0],
          result: error.message,
        }));
      }else {
        console.log(validationResult.error.message);
        errorDetails = { id: "error-login", result: validationResult.error.message };
      }
      setSignInMessages([errorDetails]);
      return;
    } else {
      setSignInMessages([]);
    }

    try {
      let dataToSend = {
        email: Email,
        password: Password,
        offeringId: 3,
      };

      if (signInNewPassword && signInNewPassword !== "") {
        dataToSend["newPassword"] = signInNewPassword;
      }

      const { data } = await axios.post(
        `https://centralizedlogin.rplusrprogram.com/login/email`,
        dataToSend
      );
      dispatch(setToken(data.result));
      dispatch(setEmail(Email));
      navigate("/main/", {
        replace: true,
      });
    } catch (err) {
      const responseData = err.response.data;

      let error = {};

      if (responseData.result === "Need to supply a new password") {
        error = { id: "error-login", result: "Please provide a new password" };
        setSignInMessages([error]);
        setSignInFormDataState({
          ...signInFormDataState,
          showNewPassword: true,
        });
      } else {
        setSignInFormDataState({
          ...signInFormDataState,
          showNewPassword: false,
        });

        if (responseData.result.code === "NotAuthorizedException") {
          error = { id: "error-login", result: "Incorrect user or password" };
        } else {
          error = { id: "error-login", result: responseData.result };
        }
        setSignInMessages([error]);
      }
    }
  };

  return (
    <form
      className="flex flex-col Form-Fields items-center justify-evenly h-3/4"
      id="loginForm"
      onSubmit={signUserIn}
    >
      <div className="flex flex-col w-9/12">
        <p className="text-xs font-medium mb-1">Email</p>
        <input
          type="text"
          className={"px-2 h-11 " + (signInEmail !== "" && "rounded-full")}
          name="signInEmail"
          autoComplete="off"
          value={signInEmail}
          onChange={handleSignInFormInputChange}
        />
      </div>

      <div className="flex flex-col w-9/12">
        <p className="text-xs font-medium mb-1">Password</p>
        <input
          type="password"
          className={"px-2 h-11 " + (signInPassword !== "" && "rounded-full")}
          name="signInPassword"
          id={"signInPassword"}
          autoComplete="off"
          value={signInPassword}
          onChange={handleSignInFormInputChange}
        />
      </div>

      <div
        className={"flex flex-col w-9/12 " + (showNewPassword ? "" : "hidden")}
      >
        <p className="text-xs font-medium mb-1">New password</p>
        <input
          type="password"
          className={
            "px-2 h-11 " + (signInNewPassword !== "" && "rounded-full")
          }
          name="signInNewPassword"
          id={"signInPassword"}
          autoComplete="off"
          value={signInNewPassword}
          onChange={handleSignInFormInputChange}
        />
      </div>

      {signInMessages && signInMessages.length > 0 && (
        <div
          className="mt-3 mb-3 w-9/12"
          style={{
            color: "red",
          }}
        >
          {signInMessages.map((message) => (
            <div key={"sign-in-error-" + message.id}>{message.result}</div>
          ))}
        </div>
      )}

      <div className="w-9/12 mt-1">
        <button
          className="w-full h-11"
          type="submit"
          style={{
            color: "white",
            backgroundColor:
              signInEmail !== null &&
              signInEmail !== "" &&
              signInPassword !== null &&
              signInPassword !== ""
                ? "#707070"
                : "#B6BEC3",
          }}
        >
          Sign In
        </button>
      </div>
      <>
        <ReactTooltip
            anchorId="signInPassword"
            place="right"
            variant="warning"
            html="Password must contain <br/> * At least 8 characters,<br/> * 1 uppercase,<br/> * 1 lowercase,<br/> * 1 number and<br/> * 1 special character"
        />
      </>
    </form>

  );
};
