import React, { useState } from "react";
import Joi from "joi-browser";
import axios from "axios";
import { navigate } from "gatsby";
import { useDispatch } from "react-redux";
import {Tooltip as ReactTooltip} from "react-tooltip";

export const PasswordResetForm = () => {
    const dispatch = useDispatch();
    const resetPasswordSchema = Joi.object().keys({
        ResetPasswordEmail: Joi.string().email().required(),
    });
    const strongPasswordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    const stringPassswordError = new Error("Password must be strong. At least one upper case alphabet. At least one lower case alphabet. At least one digit. At least one special character. Minimum eight in length")


    const resetCodeSchema = Joi.object().keys({
        ResetCode: Joi.string().required(),
        NewPassword: Joi.string().regex(strongPasswordRegex).required().error(stringPassswordError),
    });


    const [signInMessages, setSignInMessages] = useState([]);

    const [showEnterCode, setShowEnterCode] = useState(false);

    const [resetPasswordFormDataState, setResetPasswordFormDataState] = useState({
        resetPasswordEmail: "",
        resetCode: "",
        newPassword: "",
    });


    const { resetPasswordEmail, newPassword, resetCode } =
        resetPasswordFormDataState;

    const handleSignInFormInputChange = ({ target }) => {
        const valueToAdd = target.value != null ? target.value : "";

        setResetPasswordFormDataState({
            resetPasswordEmail: valueToAdd,
        });
    };

    const handleResetCodeFormInputChange = ({ target }) => {
        const valueToAdd = target.value != null ? target.value : "";

        setResetPasswordFormDataState({
            ...resetPasswordFormDataState,
            [target.name]: valueToAdd,
        });
    }

    const resetPassword = async (e) => {
        e.preventDefault();

        const Email = resetPasswordEmail;
        const ResetCode = resetCode;
        const NewPassword = newPassword;
        let validationResult = null;
        if(showEnterCode){
             validationResult = resetCodeSchema.validate({ ResetCode, NewPassword });
        }else{
             validationResult = resetPasswordSchema.validate({ ResetPasswordEmail: Email });
        }

        if (validationResult.error ) {
            let errorDetails = {};
            if(validationResult.error.details) {
                errorDetails = validationResult.error.details.map((error) => ({
                    id: error.path[0],
                    result: error.message,
                }));
            }else {
                console.log(validationResult.error.message);
                errorDetails = { id: "error-login", result: validationResult.error.message };
            }
            setSignInMessages([errorDetails]);
            return;
        } else {
            setSignInMessages([]);
        }
        try {
            if(showEnterCode){
                let dataToSend = {
                    customer_email: resetPasswordEmail,
                    password: newPassword,
                    verification_code: resetCode,
                    new_password: newPassword,
                };
                const { data } = await axios.post(
                    `https://centralizedlogin.rplusrprogram.com/forgot-password/change-password`,
                    dataToSend
                );
                if(data.status === "Success"){
                    setShowEnterCode(false);
                    navigate("/main");
                }
                return;
            }else{
                let dataToSend = {
                    customer_email: resetPasswordEmail,
                };
                const { data } = await axios.post(
                    `https://centralizedlogin.rplusrprogram.com/forgot-password/email`,
                    dataToSend
                );
                console.log('data', data);
                if(data.status === "Success"){
                    setSignInMessages([
                        {
                            id: "login-user-1",
                            result: "Please check your e-mail for the verification code",
                            color: "#B6BEC3",
                        },
                    ]);
                    setShowEnterCode(true);
                }
            }
        } catch (err) {
            const responseData = err.response.data;
            let error = {};
        }
    };

    return (
        <form
            className="flex flex-col Form-Fields items-center justify-evenly h-3/4"
            id="loginForm"
            onSubmit={resetPassword}
        >
            {!showEnterCode &&
                <div className="flex flex-col w-9/12">
                    <p className="text-xs font-medium mb-1">Email</p>
                    <input
                        type="text"
                        className={"px-2 h-11"}
                        name="signInEmail"
                        autoComplete="off"
                        value={resetPasswordEmail}
                        onChange={handleSignInFormInputChange}
                    />
                </div>
            }
            {signInMessages && signInMessages.length > 0 && (
                <div
                    className="mt-3 mb-3 w-9/12"
                    style={{
                        color: "red",
                    }}
                >
                    {signInMessages.map((message) => (
                        <div key={"sign-in-error-" + message.id}>{message.result}</div>
                    ))}
                </div>
            )}
            {
                showEnterCode && (
                    <>
                        <div className="flex flex-col w-9/12">
                            <p className="text-xs font-medium mb-1">Enter Reset Code</p>
                            <input
                                type="text"
                                className={"px-2 h-11"}
                                name="resetCode"
                                autoComplete="off"
                                onChange={handleResetCodeFormInputChange}
                            />
                        </div>
                        <div className="flex flex-col w-9/12">
                            <p className="text-xs font-medium mb-1">Enter New Password</p>
                            <input
                            type="password"
                            id={"resetPassword"}
                            className={"px-2 h-11"}
                            name="newPassword"
                            autoComplete="off"
                            onChange={handleResetCodeFormInputChange}
                            />
                        </div>
                    </>
                )
            }

            <div className="w-9/12 mt-1">
                <button
                    className="w-full h-11"
                    type="submit"
                    style={{
                        color: "white",
                        backgroundColor:
                            resetPasswordEmail !== null &&
                            resetPasswordEmail !== ""
                                ? "#707070"
                                : "#B6BEC3",
                    }}
                >
                    {showEnterCode ? 'Submit' : 'Reset Password'}
                </button>
            </div>
            <ReactTooltip
                anchorId="resetPassword"
                place="right"
                variant="warning"
                html="Password must contain <br/> * At least 8 characters,<br/> * 1 uppercase,<br/> * 1 lowercase,<br/> * 1 number and<br/> * 1 special character"
            />
        </form>
    );
};
