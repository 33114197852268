import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import LoginBanner from "../components/login/LoginBanner";
import LoginForm from "../components/login/LoginForm";
import AppPage from "../components/AppPage";
export const imagesQuery = graphql`
  {
    allContentfulLogin {
      nodes {
        id
        bgImgForm {
          id
          file {
            url
          }
        }
        bgImgHeader {
          file {
            url
          }
        }
        rrLogo {
          file {
            url
          }
        }
      }
    }
  }
`;

const LoginScreen = () => {
  const data = useStaticQuery(imagesQuery);
  const imgForm = data.allContentfulLogin.nodes[0].bgImgForm.file.url;
  const imgHeader = data.allContentfulLogin.nodes[0].bgImgHeader.file.url;
  const logo = data.allContentfulLogin.nodes[0].rrLogo.file.url;

  return (
    <AppPage>
      <LoginBanner bgImg={imgHeader} logoImg={logo} />
      <LoginForm img={imgForm} />
    </AppPage>
  );
};

export default LoginScreen;
