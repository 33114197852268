import React from "react";
import "../../assets/styles/LoginBanner.css";

const LoginBanner = ({ bgImg, logoImg }) => {
  return (
    <div>
      <div
        className="banner-background"
        style={{
          backgroundPosition: "top",
          backgroundImage: `url(${bgImg})`,
        }}
      >
        <div className="R-RContainer uppercase flex flex-col items-center justify-between">
          <div></div>

          <div className="R-RLetters-Div-Container">
            <a href="https://www.rplusrprogram.com/">
              <img src={logoImg} alt="R+R logo" className="svg-logo" />
            </a>
          </div>

          <div className="rounded-full bg-white h-10 w-10 flex items-center justify-center -mb-5">
            <a
              href="/"
              onClick={(e) => {
                let formSection = document.getElementById("FormSection");
                e.preventDefault(); // Stop Page Reloading
                formSection &&
                  formSection.scrollIntoView({ behavior: "smooth" });
              }}
            >
              <div className="arrow-down"></div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginBanner;
