import React, { useState } from "react";
import "../../assets/styles/LoginForm.css";
import { SignUpForm } from "../forms/SignUpForm";
import { SingInForm } from "../forms/SingInForm";
import {PasswordResetForm} from "../forms/PasswordResetForm";

const LoginForm = ({ img }) => {
  const bgImg = img;

  const [signFormState, setSignFormState] = useState(true);
  const [signUpFormState, setSignUpFormState] = useState(false);
  const [forgotPasswordFormState,setForgotPasswordFormState] = useState(false);
  const signInFunction = () => {
    setSignFormState(true);
    setSignUpFormState(false);
    setForgotPasswordFormState(false);
  };

  const forgotPasswordFunction = () => {
    setForgotPasswordFormState(true);
    setSignUpFormState(false)
    setSignFormState(false);
  }

  const signUpFunction = () => {
    setSignFormState(false);
    setSignUpFormState(true);
    setForgotPasswordFormState(false);
  };

  return (
      <>
        <section id="FormSection">
          <div
              className="form-background flex flex-col justify-center items-center"
              style={{
                backgroundPosition: "top",
                backgroundImage: `url(${bgImg})`,
              }}
          >
            <div className="text-white text-center Header-Letters">
              <p className="font-bold text-sm">R + R Certification</p>
              <p className="text-xs mt-2">Sign up to save your progress</p>
              <br />
            </div>

            <div className={"Form-Container " + (!signUpFormState && "hidden")}>
              <div className="bg-white SignUp-Form-Size h-full w-full flex flex-col justify-between">
                <div className="w-full flex justify-center pt-5 font-bold">
                  <div className="flex w-3/4 h-9">
                    <button
                        onClick={signInFunction}
                        className="Non-Focus-Line w-full text-center Add-Pointer font-bold"
                    >
                      Sign in
                    </button>

                    <div className="pt-1 Focus-Line w-full text-center Add-Pointer">
                      Sign up
                    </div>
                  </div>
                </div>

                <SignUpForm />

                <div className="text-center flex pb-8 font-medium justify-center">
                  <p>Already have an account?</p>{" "}
                  <button
                      className="ml-1 font-bold Grey-Text Add-Pointer"
                      onClick={signInFunction}
                  >
                    Sign in
                  </button>
                </div>
              </div>
            </div>

            <div className={"Form-Container " + (!signFormState && "hidden")}>
              <div
                  className={
                    "bg-white SignIn-Form-Size w-full flex flex-col justify-between"
                  }
              >
                <div className="w-full flex justify-center pt-5 font-bold">
                  <div className="flex w-3/4 h-9">
                    <div className="pt-1 Focus-Line w-full text-center Add-Pointer">
                      Sign in
                    </div>

                    <button
                        onClick={signUpFunction}
                        className="Non-Focus-Line w-full text-center Add-Pointer font-bold"
                    >
                      <p>Sign up</p>
                    </button>
                  </div>
                </div>

                <SingInForm />

                <div className="text-center flex pb-8 font-medium justify-center">
                  <p>Create an account</p>{" "}
                  <button
                      className="ml-1 font-bold Grey-Text Add-Pointer"
                      onClick={signUpFunction}
                  >
                    Sign up
                  </button>
                </div>
                <div className="text-center flex pb-8 font-medium justify-center">
                  <button
                      className="ml-1 font-bold Grey-Text Add-Pointer"
                      onClick={forgotPasswordFunction}
                  >
                    Forgot your password?
                  </button>
                </div>
              </div>
            </div>

            <div className={"Form-Container " + (!forgotPasswordFormState  && "hidden")}>
              <div
                  className={
                    "bg-white SignIn-Form-Size w-full flex flex-col justify-between"
                  }
              >
                <div className="w-full flex justify-center pt-5 font-bold">
                  <div className="flex w-3/4 h-9">
                    <div className="pt-1 Focus-Line w-full text-center Add-Pointer">
                      Sign in
                    </div>

                    <button
                        onClick={signUpFunction}
                        className="Non-Focus-Line w-full text-center Add-Pointer font-bold"
                    >
                      <p>Sign up</p>
                    </button>
                  </div>
                </div>

                <PasswordResetForm />

                <div className="text-center flex pb-8 font-medium justify-center">
                  <p>Create an account</p>{" "}
                  <button
                      className="ml-1 font-bold Grey-Text Add-Pointer"
                      onClick={signUpFunction}
                  >
                    Sign up
                  </button>
                </div>
                <div className="text-center flex pb-8 font-medium justify-center">
                  <button
                      className="ml-1 font-bold Grey-Text Add-Pointer"
                      onClick={signInFunction}
                  >
                    Sign in
                  </button>
                </div>
              </div>
            </div>


          </div>
        </section>
      </>
  );
};

export default LoginForm;
